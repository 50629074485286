<template>
  <div>
    <div id="timer"></div>
  </div>
</template>

<script>
export default {
  name: "FlipClock",
  props: ['date'],
  data: () => ({}),
  methods: {
    init() {
      const DATE = this.date || '';
      const DATE_IN_MS = new Date(DATE).valueOf();
      const Countdown = window.Countdown;
      const cd = new Countdown({
        cont: document.querySelector("#timer"),
        date: DATE_IN_MS,
        countdown: false,
        outputTranslation: {
          day: "Days",
          hour: "Hours",
          minute: "Minutes",
          second: "Seconds"
        },
        endCallback: null,
        outputFormat: "day|hour|minute|second"
      });
      cd.start();
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss">
#timer {
  display: flex;
  justify-content: center;
  justify-items: center;
  margin-bottom: 1em;
}
.countDown__interval_cont:nth-child(n+1):not(:last-child) {
  margin-right: 1.75em;
}
@media screen and (max-width: 479px) {
  .countDown__digit_cont {
    font-size: 2.5em !important;
    width: 0.9em !important;
  }
  .countDown__interval_basic_cont_description {
    font-size: 1em !important;
  }
  .countDown__interval_cont:nth-child(n+1):not(:last-child) {
    margin-right: 1em;
  }
}
</style>
